import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import FasciaPaginaGenerica from "../components/page-content/fascia-pagina-generica";
import withPreview from "../utility/with-preview";
import useInitDataLayer from "../utility/useInitDataLayer";
import { useHandleRecaptchaBadge } from "../utility/useHandleRecaptchaBadge";

const PaginaGenericaTemplate = ({ data, location }) => {
  const {
    title,
    headerType,
    footerType,
    showSmartBannerApp,
    link,
    seo,
    fasce,
    impressionDetail,
    disableVwoAbTesting,
  } = data.page;

  useInitDataLayer(location, impressionDetail);
  useHandleRecaptchaBadge(fasce);
  return (
    <Layout
      headerType={headerType?.value?.[0]}
      footerType={footerType?.value?.[0]}
      banner={showSmartBannerApp?.value}
      location={location}
    >
      <Seo
        title={seo?.content?.htmlTitle?.value || title?.value}
        description={seo?.content?.metaDescription?.value}
        ogImage={seo?.content?.ogImage?.node}
        path={link}
        robots={seo?.content?.metaRobots?.value?.[0]}
        disableVwoAbTesting={disableVwoAbTesting?.value}
      />
      <div data-template="pagina-generica">
        {fasce?.map((fascia, i) => (
          <FasciaPaginaGenerica
            fascia={fascia.value}
            key={i}
            location={location}
            title={seo?.content?.htmlTitle?.value || title?.value}
          />
        ))}
      </div>
    </Layout>
  );
};

export default withPreview(PaginaGenericaTemplate, {
  subField: "page",
  fixed: true,
});
export const query = graphql`
  query ($id: String) {
    page: liferayJskLayoutPaginaGenerica(id: { eq: $id }) {
      liferayFields {
        siteId
        articleId
      }
      headerType {
        value
      }
      footerType {
        value
      }
      impressionDetail {
        value
      }
      showSmartBannerApp {
        value
      }
      link
      seo {
        content {
          htmlTitle {
            value
          }
          metaDescription {
            value
          }
          ogImage {
            node {
              resize(width: 1200) {
                src
                width
                height
              }
            }
          }
          metaRobots {
            value
          }
        }
      }
      title {
        value
      }
      disableVwoAbTesting {
        value
      }
      fasce {
        value {
          ...FasciaPaginaGenericaFragment
        }
      }
    }
  }
`;
